<template>
  <header id="app-header">
    <nav>
      <router-link class="logo-section" to="/">
        <img class="logo" src="../assets/tradebot-avatar.png" />
        <div class="stack title" style="--stacks: 3">
          <span style="--index: 0">TradeBot</span>
          <span style="--index: 1">TradeBot</span>
          <span style="--index: 2">TradeBot</span>
        </div>
      </router-link>
      <ul v-show="!isMobile" class="navigation">
        <li>
          <router-link class="link" to="/whitepaper">Whitepaper</router-link>
        </li>
        <li><router-link class="link" to="/token">Token</router-link></li>
        <li><router-link class="link" to="/socials">Socials</router-link></li>
        <li><router-link class="link" to="/burns">Buybacks</router-link></li>
        <li><router-link class="link" to="/buy">Buy $TRADEBOT</router-link></li>
      </ul>
      <div class="hamburger-menu">
        <font-awesome-icon
          id="hamburger-icon"
          v-show="isMobile"
          v-on:click="toggleMobileNav"
          v-bind:class="{ 'icon-active': isMobileNavEnabled }"
          icon="fa-solid fa-bars"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="isMobileNavEnabled" class="dropdown-nav">
          <li>
            <router-link
              v-on:click="disableMobileNav"
              class="link"
              to="/whitepaper"
              >Whitepaper</router-link
            >
          </li>
          <li>
            <router-link v-on:click="disableMobileNav" class="link" to="/token"
              >Token</router-link
            >
          </li>
          <li>
            <router-link
              v-on:click="disableMobileNav"
              class="link"
              to="/socials"
              >Socials</router-link
            >
          </li>
          <li>
            <router-link v-on:click="disableMobileNav" class="link" to="/burns"
              >Buybacks</router-link
            >
          </li>
          <li>
            <router-link v-on:click="disableMobileNav" class="link" to="/buy"
              >Buy $TRADEBOT</router-link
            >
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      scrollPosition: null,
      isMobile: true,
      isMobileNavEnabled: false,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    toggleMobileNav() {
      this.isMobileNavEnabled = !this.isMobileNavEnabled;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.isMobileNavEnabled = false;
      }
    },
    disableMobileNav() {
      this.isMobileNavEnabled = false;
    },
  },
};
</script>

<style scoped>
.stack span {
  color: rgb(227, 227, 242);
  font-weight: bold;
  font-size: 30px;
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 6s alternate-reverse;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

#app-header {
  background-color: rgba(18, 18, 18, 0.95);
  gap: 15px;
  z-index: 99;
  height: 70px; /* make sure to update top of .dropdown-nav */
  box-shadow: 0 0 2px 0 whitesmoke, 0 2px 7px 0 grey;
}

nav {
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: 0.5s ease all;
  justify-content: space-between;
  margin: 0 auto;
}

.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

ul,
.link {
  font-weight: 500;
  color: #fff;
  list-style: none;
  text-decoration: none;
}

li {
  text-transform: uppercase;
  padding: 16px;
  margin-left: 16px;
}

.link {
  font-size: 15px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  color: rgb(227, 227, 242);
}

.link:hover {
  color: rgb(78, 111, 211);
  border-color: rgb(78, 111, 211);
}

.logo {
  width: 50px;
  transition: 0.5s ease all;
  background-color: transparent;
  padding-top: 10px;
}

.logo-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 10px;
}

.title:hover {
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.hamburger-menu {
  position: absolute;
  top: 24px;
  right: 24px;
  color: white;
}

#hamburger-icon {
  cursor: pointer;
  font-size: 24px;
  transition: 0.8s ease all;
}

.icon-active {
  transform: rotate(180deg);
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.9);
  top: 70px; /* make sure this matches height of app-header */
  left: 0;
  padding: 0px;
  margin: 0px;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0px);
}

.dropdown-nav > li {
  margin-left: 5px;
}

.dropdown-nav > li > .link {
  color: white;
}

.dropdown-nav > li > .link:hover {
  color: rgb(78, 111, 211);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #app-header {
    height: 60px;
  }

  .dropdown-nav {
    top: 60px;
  }

  .hamburger-menu {
    position: absolute;
    top: 20px;
    right: 24px;
    color: white;
  }
}
</style>
